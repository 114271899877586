import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const resources = {
    en: {
        translation: {
            "title": "Avicenna ERP Solutions",
            "subtitle": "Streamlining Medical Clinic Operations",
            "hero.title": "Revolutionize Your Clinic's Efficiency",
            "hero.description": "Comprehensive ERP solutions tailored for medical clinics",
            "hero.cta": "Request a Demo",
            "features.title": "Our Key Features",
            "about.title": "About Avicenna ERP Solutions",
            "about.description": "We specialize in providing cutting-edge ERP solutions designed specifically for medical clinics. Our system integrates all aspects of clinic management, from patient scheduling to financial reporting, helping you focus on what matters most: patient care.",
            "footer": "© 2024 Avicenna ERP Solutions. All rights reserved.",
            "contact-us": "Contact us",
            "features.appointmentsTracking": "Appointments Tracking",
            "features.appointments.description": "We have awesome appointments tracking",
            "features.notifications": "Notifications",
            "features.facilityManagement": "Facility Management",
            "features.financeManagement": "Finance Management",
            "features.clientHistoryTracking": "Client History Tracking",
            "features.notifications.description": "Receive notifications for important clinic events.",
            "features.notifications.details": "Keep everyone in the clinic informed with real-time notifications. Whether it's an upcoming appointment, an important message, or an urgent update, staff will receive timely alerts to ensure smooth operations and improved communication.",
            "features.appointments": {
                "description": "Manage and track all appointments in the system.",
                "details": "With an easy-to-use interface, clinic staff can quickly view, create, and modify patient appointments, ensuring better scheduling and reduced no-shows. The system provides automated reminders and conflict checks for efficient time management."
            },
            "features.management": {
                "description": "Efficiently manage your clinic's facilities.",
                "details": "Track and manage clinic resources, including rooms, equipment, and maintenance schedules. The system helps avoid overbooking, manage assets, and ensure all facilities are in optimal condition for patient care."

            },
            "features.finance": {
                "description": "Track and manage your clinic's finances.",
                "details": "Gain insight into clinic revenues, expenses and overall financial health. Financial management tools help with billing, tracking payments, categorising expenses, and generating comprehensive financial reports to support decision making."

            },
            "features.history": {
                "description": "Keep a complete record of client history.",
                "details": "Access a comprehensive view of a patient's medical history, including previous appointments, treatments and financial transactions. This information enables personalised treatment and simplifies follow-up procedures, ultimately improving patient satisfaction."

            },
            "demo.title": "Request demo",
            "demo.name": "Name",
            "demo.email": "Email",
            "demo.message": "Message",
            "demo.submit": "Send Request"
        }
    }, ru: {
        translation: {
            "title": "Avicenna ERP Решения",
            "subtitle": "Оптимизация работы медицинских клиник",
            "hero.title": "Революционизируйте эффективность вашей клиники",
            "hero.description": "Комплексные ERP-решения, разработанные для медицинских клиник",
            "hero.cta": "Запросить демо",
            "features.title": "Наши ключевые особенности",
            "about.title": "О Avicenna ERP Решениях",
            "about.description": "Мы специализируемся на предоставлении передовых ERP-решений, разработанных специально для медицинских клиник. Наша система интегрирует все аспекты управления клиникой, от планирования приема пациентов до финансовой отчетности, помогая вам сосредоточиться на самом главном: уходе за пациентами.",
            "footer": "© 2024 Avicenna ERP Решения. Все права защищены.",
            "contact-us": "Свяжитесь с нами",
            "features.appointmentsTracking": "Отслеживание приемов",
            "features.notifications:": "Уведомления",
            "features.facilityManagement": "Управление объектами",
            "features.financeManagement": "Управление финансами",
            "features.clientHistoryTracking": "Отслеживание истории клиентов",
             "features.notifications.description":" Получайте уведомления о важных событиях в клинике.",
            "features.notifications.details":"Держите всех сотрудников клиники в курсе событий с помощью уведомлений в режиме реального времени. Будь то предстоящая встреча, важное сообщение или срочное обновление, сотрудники будут получать своевременные оповещения для обеспечения бесперебойной работы и улучшения коммуникации.",
            "features.appointments": {
                "description": "Управляйте и отслеживайте все приемы в системе.",
                "details":"Благодаря простому в использовании интерфейсу сотрудники клиники могут быстро просматривать, создавать и изменять записи на прием пациентов, обеспечивая более эффективное планирование и сокращая количество неявок. Система обеспечивает автоматические напоминания и проверку конфликтов для эффективного управления временем."
            },
            "features.management": {
                "description": "Эффективно управляйте объектами вашей клиники.",
                "details": "Отслеживайте и управляйте ресурсами клиники, включая помещения, оборудование и графики технического обслуживания. Система помогает избежать перегруженности, управлять активами и обеспечивать оптимальное состояние всех помещений для обслуживания пациентов"
            },
            "features.finance": {
                "description": "Отслеживайте и управляйте финансами вашей клиники.",
                "details": "Получите информацию о доходах, расходах и общем финансовом состоянии клиники. Инструменты управления финансами помогают выставлять счета, отслеживать платежи, классифицировать расходы и создавать комплексные финансовые отчеты для поддержки принятия решений."
            },
            "features.history": {
                "description": "Ведите полный учет истории клиентов.",
                "details": "Получите доступ к полной информации об истории болезни пациента, включая предыдущие визиты, лечение и финансовые операции. Эта информация позволяет подобрать индивидуальное лечение и упростить последующие процедуры, что в конечном итоге повышает удовлетворенность пациентов."
            },
            "demo.title": "Запросить демо",
            "demo.name": "Имя",
            "demo.email": "Электронная почта",
            "demo.message": "Сообщение",
            "demo.submit": "Отправить запрос"
        }
    }, tr: {
        translation: {
            "title": "Avicenna ERP Çözümleri",
            "subtitle": "Tıbbi Klinik Operasyonlarını Düzenleme",
            "hero.title": "Kliniğinizin Verimliliğini Devrimleştirin",
            "hero.description": "Tıbbi klinikler için özel olarak tasarlanmış kapsamlı ERP çözümleri",
            "hero.cta": "Demo Talep Et",
            "features.title": "Temel Özelliklerimiz",
            "about.title": "Avicenna ERP Çözümleri Hakkında",
            "about.description": "Özellikle tıbbi klinikler için tasarlanmış en son teknoloji ERP çözümleri sunmakta uzmanız. Sistemimiz, hasta randevularından finansal raporlamaya kadar klinik yönetiminin tüm yönlerini entegre ederek, en önemli şeye odaklanmanıza yardımcı olur: hasta bakımı.",
            "footer": "© 2024 Avicenna ERP Çözümleri. Tüm hakları saklıdır.",
            "contact-us": 'Bize ulaşın',
            "features.appointmentsTracking": "Randevu Takibi",
            "features.notifications": "Bildirimler",
            "features.facilityManagement": "Tesis Yönetimi",
            "features.financeManagement": "Finans Yönetimi",
            "features.clientHistoryTracking": "Müşteri Geçmişi Takibi",
            "features.notifications.description":  "Klinikte önemli olaylar hakkında bildirimler alın.",
            "features.notifications.details":"Gerçek zamanlı bildirimlerle klinikteki herkesi bilgilendirin. İster yaklaşan bir randevu, ister önemli bir mesaj veya acil bir güncelleme olsun, personele zamanında uyarılar gönderilerek sorunsuz operasyon ve gelişmiş bir iletişim sağlayacaktır.",
            "features.appointments": {
                "description": "Sistemdeki tüm randevuları yönetin ve takip edin.",
                "details": "Kullanımı kolay bir arayüz sayesinde klinik personeli hasta randevularını hızlı bir şekilde görüntüleyebilir, oluşturabilir ve değiştirebilir. Program verimli bir zaman yönetimi için otomatik hatırlatma ve uyuşmazlık kontrolü de sağlamaktadır.",
            },
            "features.management": {
                "description": "Klinik tesislerinizi etkin bir şekilde yönetin.",
                "details": "Oda, ekipman ve bakım programları dahil olmak üzere klinik kaynaklarını takip edin ve yönetin. Sistem kapasite aşımını önlemeye, varlıkları yönetmeye ve tüm tesislerin hasta bakımı için en uygun durumda olmasını sağlamaya yardımcı olmaktadır.",
            },
            "features.finance": {
                "description": "Klinik finansmanınızı izleyin ve yönetin.",
                "details": "Klinik gelirleri, giderleri ve genel finansal sağlık durumu hakkında bilgi edinin. Finansal yönetim araçları faturalandırma, ödemeleri izleme, giderleri kategorize etme ve karar vermeyi desteklemek için kapsamlı finansal raporlar oluşturmaya yardımcı olur.",
            },
            "features.history": {
                "description": "Müşteri geçmişinin tam kaydını tutun.",
                "details": "Önceki randevular, tedaviler ve finansal işlemler de dahil olmak üzere bir hastanın tıbbi geçmişinin kapsamlı bir görünümüne erişin. Bu bilgiler kişiselleştirilmiş tedaviyi mümkün kılar ve takip prosedürlerini basitleştirerek sonuçta hasta memnuniyetini artırır."
            },
            "demo.title": "Demo Talep Et",
            "demo.name": "İsim",
            "demo.email": "E-posta",
            "demo.message": "Mesaj",
            "demo.submit": "Talebi Gönder"
        }
    }, sr: {
        translation: {
            "title": "Avicenna ERP Rešenja",
            "subtitle": "Unapređenje operacija medicinskih klinika",
            "hero.title": "Revolucionalizujte efikasnost vaše klinike",
            "hero.description": "Sveobuhvatna ERP rešenja prilagođena medicinskim klinikama",
            "hero.cta": "Zatražite demo",
            "features.title": "Naše ključne karakteristike",
            "about.title": "O Avicenna ERP Rešenjima",
            "about.description": "Specijalizovani smo za pružanje najsavremenijih ERP rešenja dizajniranih posebno za medicinske klinike. Naš sistem integriše sve aspekte upravljanja klinikom, od zakazivanja pacijenata do finansijskog izveštavanja, pomažući vam da se fokusirate na ono što je najvažnije: negu pacijenata.",
            "footer": "© 2024 Avicenna ERP Rešenja. Sva prava zadržana.",
            "contact-us": "Pomoć i podrška",
            "features.appointmentsTracking": "Praćenje zakazanih termina",
            "features.notifications": "Obaveštenja",
            "features.facilityManagement": "Upravljanje objektima",
            "features.financeManagement": "Upravljanje finansijama",
            "features.clientHistoryTracking": "Praćenje istorije klijenata",
            "features.notifications.description": "Primajte obaveštenja o važnim događajima u klinici.",
            "features.appointments": {
                "description": "Upravljajte i pratite sve termine u sistemu."
            },
            "features.management": {
                "description": "Efikasno upravljajte objektima vaše klinike."
            },
            "features.finance": {
                "description": "Pratite i upravljajte finansijama vaše klinike."
            },
            "features.history": {
                "description": "Vodite potpunu evidenciju istorije klijenata."
            },
            "demo.title": "Zatražite demo",
            "demo.name": "Ime",
            "demo.email": "E-pošta",
            "demo.message": "Poruka",
            "demo.submit": "Pošalji zahtev"
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources, lng: "en", // default language
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
