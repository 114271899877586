import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import './i18n'
import './App.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const App = () => {
    const {t, i18n} = useTranslation();

    const highlights = [
        {id: 'appointments', title: t('features.appointmentsTracking'), icon: '📅'},
        {id: 'notifications', title: t('features.notifications'), icon: '🔔'},
        {id: 'management', title: t('features.facilityManagement'), icon: '🏥'},
        {id: 'finance', title: t('features.financeManagement'), icon: '💰'},
        {id: 'history', title: t('features.clientHistoryTracking'), icon: '📋'},
    ];

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(false);
            }
        };

        if (isModalOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isModalOpen]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const subject = encodeURIComponent('Demo Request for Avicenna ERP Solutions');
        const body = encodeURIComponent(`Name: ${formData.name}\nEmail: ${formData.email}\n\nMessage: ${formData.message}`);
        // do not use it
        window.location.href = `mailto:your-email@example.com?subject=${subject}&body=${body}`;
        setIsModalOpen(false);
    };

    return (
        <div className="landing-page">
            <header>
                <h1>{t('title')}</h1>
                <p>{t('subtitle')}</p>
                <div className="language-selector">
                    <button onClick={() => changeLanguage('en')}>English</button>
                    <button onClick={() => changeLanguage('ru')}>Русский</button>
                    <button onClick={() => changeLanguage('tr')}>Türkçe</button>
                    <button onClick={() => changeLanguage('sr')}>Српски</button>
                </div>
            </header>

            <main>
                <section className="hero">
                    <h2>{t('hero.title')}</h2>
                    <p>{t('hero.description')}</p>
                    <button onClick={() => setIsModalOpen(true)}>{t('hero.cta')}</button>
                </section>

                <section className="highlights">
                    <h2>{t('features.title')}</h2>
                    <div className="highlight-grid">
                        {highlights.map((highlight, index) => (
                            <div key={index} className="highlight-item">
                                <a href={`#${highlight.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
                                    <span className="highlight-icon">{highlight.icon}</span>
                                    <h3>{highlight.title}</h3>
                                </a>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="highlights">
                    <h2>{t('features.title')}</h2>
                    {highlights.map((highlight) => (
                        <div key={highlight.id} id={highlight.id} className="feature-banner">
                            <h3>{highlight.title}</h3>
                            <h4>{t(`features.${highlight.id}.description`)}</h4>
                            <p>{t(`features.${highlight.id}.details`)}</p>
                        </div>
                    ))}
                </section>

                <section className="about">
                    <h2>About Avicenna ERP Solutions</h2>
                    <p>We specialize in providing cutting-edge ERP solutions designed specifically for medical clinics.
                        Our
                        system integrates all aspects of clinic management, from patient scheduling to financial
                        reporting, helping
                        you focus on what matters most: patient care.</p>
                </section>
            </main>

            <footer>
                <a href="https://wa.me/+905461919048" style={{color: 'white'}}><FontAwesomeIcon
                    icon={["fas", "phone"]}/>{t('contact-us')}</a>
                <p>{t('footer')}</p>
            </footer>

            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal" ref={modalRef}>
                        <h2>{t('demo.title')}</h2>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="name">{t('demo.name')}</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />

                            <label htmlFor="email">{t('demo.email')}</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />

                            <label htmlFor="message">{t('demo.message')}</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                                required
                            ></textarea>

                            <button type="submit">{t('demo.submit')}</button>
                        </form>
                        <button className="close-modal" onClick={() => setIsModalOpen(false)}>
                            &times;
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default App;
